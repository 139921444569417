@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

:root {
  --primary-color: #f1eee9;
  --secondary-color: #25d6f5;
  --tertiary-color: #78caf9;
  --text-dark: #13313c;
  --text-light: #475569;
  --white: #e0dcdc;
  --max-width: 1200px;
  --header-font: "Rubik", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.section__container {
  max-width: var(--max-width);
  margin: auto;
  padding: 5rem 1rem;
}

img {
  display: flex;
  width: 100%;
}

a {
  text-decoration: none;
  transition: 0.3s;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: var(--primary-color);
}

nav {
  position: fixed;
  isolation: isolate;
  width: 100%;
  z-index: 9;
}

.nav__header {
  padding: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--text-dark);
}
.nav__logo{
  width: 13rem;
}
.nav__logo h1 {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;

  color: #ffffff;
}

.nav__logo h1 span {
  padding: 3px 7px;
  margin-inline: 5px;
  font-size: 1rem;
  background-color: var(--secondary-color);
  border-radius: 100%;
}

.nav__menu__btn {
  font-size: 1.5rem;
  color: var(--white);
  cursor: pointer;
}

.nav__links {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  padding: 2rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--text-dark);
  transition: 0.5s;
  z-index: -1;
  transform: translateY(-100%);
}

.nav__links.open {
  transform: translateY(0);
}

.nav__links a {
  font-weight: 500;
  color: var(--white);
  transition: 0.3s;
}

.nav__links a:hover {
  color: var(--secondary-color);
}

.nav__btns {
  display: none;
}

.header__container {
  display: grid;
  gap: 2rem;
}

.header__image img {
  max-width: 550px;
  margin-inline: auto;
}

.header__content {
  text-align: center;
}

.header__content h2 {
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--text-dark);
}

.header__content h2 {
  margin-bottom: 1rem;
  font-size: 3.25rem;
  font-weight: 600;
  font-family: var(--header-font);
  line-height: 3rem;
  color: var(--text-dark);
}

.header__content p {
  margin-bottom: 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.75rem;
  color: var(--text-light);
}
.main_body{
  display: flex;
  flex-direction: column;
}
.main_body h1{
  text-align: center;
  margin-bottom: 1rem;
  font-size: 3.25rem;
  font-weight: 600;
  font-family: var(--header-font);
  line-height: 3rem;
  color: var(--text-dark);
}

.min_video h1{
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
  font-family: var(--header-font);
  line-height: 1.5rem;
  color: var(--text-dark);
}
.min_video video{
  margin: auto;
  display: flex;
  width: 90%;
  margin-bottom: 1rem;
}


.sub__body{
  display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(3, 1fr);
grid-column-gap: 0px;
grid-row-gap: 10px;
padding: 1rem;
}

.header__btns {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.header__btns img {
  max-width: 150px;
  border-radius: 5px;
}

.header__image img {
  width: 10rem;
   height: 16rem;
 }
 .sub_pat h2{
  margin-bottom: 1rem;
 }
 .sub_pat {
  margin-bottom: 2rem;
 }
 .s_tab{
  display: flex;
  flex-direction: row;
  align-items: center!important;
  justify-content: space-between;
gap: 20px;
}
.right {
  flex-direction: row-reverse;
}


.s_tab img{
  width: auto;
  height: 100px;
}
.p_d{
  padding: 0.2rem;
}
.bd_img{
  background-image: url("../public/assets/bg-wave-blue-light.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 0.5rem;
  padding-top: 3rem;

}

.container{
 
  /* transform: translate(-50%, -50%); */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 4rem;
  height: 300px;
}

.container h1{
  text-align: center;
  margin: auto;
  margin-bottom: 1rem;
  font-size: 3.25rem;
  width: 70%;
  font-weight: 600;
  font-family: var(--header-font);
  line-height: 3rem;
  color: var(--text-dark);
  margin-bottom: 20px;
}

.container .email{
  height: 50px;
  position: relative;
}

.container .email input{
  height: 100%;
  width: 100%;
  padding-left: 50px;
  font-size: 18px;
  outline: none;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 50px;
  border: 2px solid coral;
}

.container .email .icon{
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 18px;
}

.container .email button{
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(-50%, -50%);
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid gray;
  outline: none;
  background: #286aeb;
  color: white;
  transition: 0.3s;
}

.container .email button:hover{
  background: #1b3d94;
}


@media (width > 768px) {
  .nav__logo h1 {
   
  
    color: #000000;
  }
  nav {
    position: static;
    padding-block: 2rem 0;
    padding-inline: 1rem;
    max-width: var(--max-width);
    margin-inline: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .nav__header {
    flex: 1;
    padding: 0;
    background-color: transparent;
  }

  .nav__logo a {
    font-size: 2rem;
    color: var(--text-dark);
  }

  .nav__logo a span {
    padding: 6px 9px;
    color: var(--white);
  }

  .nav__menu__btn {
    display: none;
  }

  .nav__links {
    position: static;
    padding: 0;
    flex-direction: row;
    background-color: transparent;
    transform: none;
  }

  .nav__links a {
    padding-block: 5px;
    color: var(--text-light);
    border-bottom: 2px solid transparent;
  }

  .nav__links a:hover {
    border-color: var(--secondary-color);
  }

  .nav__btns {
    display: flex;
    flex: 1;
  }

  .nav__btns .btn {
    padding: 0.75rem 2rem;
    outline: none;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    white-space: nowrap;
    color: rgb(54, 53, 53);
    background-color: var(--tertiary-color);
    border-radius: 5rem;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s;
    cursor: pointer;
  }

  .nav__btns .btn:hover {
    color: rgb(7, 5, 5);
    background-color: var(--secondary-color);
  }

  .header__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .header__image {
    grid-area: 1/2/2/3;
  
  }
  .header__image img {
   width: 20rem;
    height: 35rem;
  }

  .header__content {
    text-align: left;
  }

  .sub__body{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 0px;
    padding: 2rem;
  }
  .min_video video{
    margin: auto;
    display: flex;

    padding: 2rem;
    width: 700px;
  }
  .min_video h1{
    text-align: center;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 3.25rem;
    width: 50%;
    font-weight: 600;
    font-family: var(--header-font);
    line-height: 3rem;
    color: var(--text-dark);
  }

  .sim_bod{
    display: flex;
    padding: 2rem;
    margin: auto;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .sim_bod img{
   width: auto;
   height: 500px;
    border-radius: 1.5rem;
  }
  .sim_bod p{
    width: 40%;
    text-align: left;
    font-size: 1.1rem;
    margin-top: 2rem;
    font-weight: 500;
    line-height: 1.75rem;
    color: var(--text-light);
  }
  .header__btns {
    justify-content: flex-start;
  }

  .p_d{
    padding: 1rem;
  }
  .s_tab{
    display: flex;
    flex-direction: column;
    align-items: center!important;
    justify-content: space-between!important;
  }
  .s_tab img{
    width: auto;
    height: 100px;
  }
  .f_form{
    min-width: 700px;
    min-height: 700px;
  }

  .container{
 
    /* transform: translate(-50%, -50%); */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-bottom: 4rem;
    height: 300px;
  }
  
  .container h1{
    text-align: center;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 3.25rem;
    width: 70%;
    font-weight: 600;
    font-family: var(--header-font);
    line-height: 3rem;
    color: var(--text-dark);
    margin-bottom: 20px;
  }
  
  .container .email{
    height: 50px;
    position: relative;
  }
  
  .container .email input{
    height: 100%;
    width: 450px;
    padding-left: 50px;
    font-size: 18px;
    outline: none;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 50px;
    border: 2px solid coral;
  }
  
  .container .email .icon{
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
  
  .container .email button{
    position: absolute;
    top: 50%;
    right: -30px;
    transform: translate(-50%, -50%);
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 50px;
    border: 1px solid gray;
    outline: none;
    background: #286aeb;
    color: white;
    transition: 0.3s;
  }
  
  .container .email button:hover{
    background: #1b3d94;
  }

  .slider-buttons {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .slick-slide img {
    display: block;
    width: 500px;
    height: auto;
    margin: 0 auto;
    overflow: hidden;
  }
  
  .slick-slide video {
    display: block;
    width: 500px;
    height: auto;
    margin: 0 auto;
  }
  .slider-buttons button {
    font-size: 3rem;
    color: rgb(0, 0, 0);
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .slider-buttons button:hover {
    font-size: 3.5rem;
  }
  
}

.slick-slide img {
  display: block;
  width: 90%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
}

.slick-slide video {
  display: block;
  width: 90%;
  height: auto;
  margin: 0 auto;
}

.slider-container {
  position: relative;
  max-width: 800px; /* Adjust as needed */
  margin: 0 auto;
}

.slider-buttons {
  /* display: none; */
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.slider-buttons button {
  font-size: 3rem;
  /* display: none; */
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px;
  cursor: pointer;
}

.slider-buttons button:hover {
  font-size: 3.5rem;
}






footer{
  background-color: #111;
}
.footerContainer{
  width: 100%;
  padding: 70px 30px 20px ;
}
.socialIcons{
  display: flex;
  justify-content: center;
}
.socialIcons a{
  text-decoration: none;
  padding:  8px;
  height: 2rem;
  width: 2rem;
  background-color: white;
  margin: 10px;
  border-radius: 50%;
}
.socialIcons a .icons{
  font-size: 1em;
  color: black;
  opacity: 0,9;
}
/* Hover affect on social media icon */
.socialIcons a:hover{
  background-color: #111;
  transition: 0.5s;
}
.socialIcons a:hover .icons{
  color: white;
  transition: 0.5s;
}
.footerNav{
  margin: 30px 0;
}
.footerNav ul{
  display: flex;
  justify-content: center;
  list-style-type: none;
}
.footerNav ul li a{
  color:white;
  margin: 20px;
  text-decoration: none;
  font-size: 1em;
  opacity: 0.7;
  transition: 0.5s;

}
.footerNav ul li a:hover{
  opacity: 1;
}
.footerBottom{
  background-color: #000;
  padding: 20px;
  text-align: center;
}
.footerBottom p{
  color: white;
}
.designer{
  opacity: 0.7;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 0px 5px;
}
@media (max-width: 700px){
  .footerNav ul{
      flex-direction: column;
  } 
  .footerNav ul li{
      width:100%;
      text-align: center;
      margin: 10px;
  }
  .socialIcons a{
      padding: 8px;
      margin: 4px;
  }
}


.cont {

  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: var(--primary-color);
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 2rem;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: var(--tertiary-color);
  position: relative;
  overflow: hidden;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #25d6f5);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #25d6f5;
  transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: none;
  padding: 0.6rem 1.2rem;
  color: #fff;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 1000;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #1abc9c;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 500;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #78caf9;
  top: 50%;
  transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.3rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #1abc9c;
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 28px;
  margin-right: 0.7rem;
}

.social-media {
  padding: 2rem 0 0 0;
}

.social-media p {
  color: #333;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #25d6f5, #25d6f5);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #25d6f5;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #25d6f5, #25d6f5);
  bottom: 50%;
  right: 50%;
  transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: initial;
    top: -75px;
    right: 65px;
    transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: initial;
    right: 70px;
  }

  .square {
    transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .cont {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
}